.contact-form,
.contest-form {
	.fui-form-container {
		@apply font-chivo-regular;
		@apply text-textfield;

		.fui-alert {
			@apply font-chivo-regular;
		}

		.fui-input {
			@apply bg-transparent;
		}

		.fui-select {
			@apply bg-transparent;
		}

		.fui-input .selected {
			@apply text-body-1;
		}

		.fields-kontaktformular-message {
			textarea {
				height: 170px;
			}
		}

		.fui-checkbox-label {
			@apply text-body-1;

			&::before {
				@bg-transparent;
			}
		}

		.fui-field {
			@apply relative;
			@apply text-left;
		}

		.fui-checkbox {
			@apply flex;

			label {
				@apply text-body-1;

				&::before {
					border-radius: 2px;
					background-color: #cbd5e0;
					border: 2px solid #cbd5e0;
				}

				a {
					@apply underline;
				}
			}
		}

		.fui-type-dropdown {
			.fui-select {
				background-image: url("../../images/ic_dropdown_accending.svg");
			}

			.fui-label {
				@apply bg-grey-200 w-1/2;
				pointer-events: none;

				&.float {
					@apply w-auto;
				}
			}
		}

		.fui-label {
			@apply text-textfield;
			@apply top-0.5;
			@apply left-4;
			@apply m-0;
			@apply p-1;
			@apply absolute;
			transition: top 0.2s, bottom 0.2s, right 0.2s, left 0.2s;
	
			&.float {
				@apply text-primary-500;
				@apply bg-grey-200;
				@apply -top-3;
			}
		}

		.fui-error {
			.fui-label {
				@apply text-error;
			}
		}

		.send-button-contactform {
			.fui-submit {
				@apply flex;
				@apply justify-center;
				@apply items-center;
				@apply font-kanit-semi-bold;
				@apply text-contact-form;
				@apply bg-primary-500;
				@apply rounded-full;
				@apply no-underline;
				@apply px-16;
				@apply py-4;
				@apply border-none;

				&:hover,
				&.active {
					@apply cursor-pointer;
					@apply bg-primary-600;
				}
			}
		}

		.fui-submit {
			@apply flex;
			@apply justify-center;
			@apply items-center;
			@apply font-kanit-semi-bold;
			@apply text-contact-form;
			@apply bg-primary-500;
			@apply rounded-full;
			@apply no-underline;
			@apply px-8;
			@apply py-4;
			@apply border-none;

			&:hover,
			&.active {
				@apply cursor-pointer;
				@apply bg-primary-600;
			}
		}

		.fui-input.fui-error,
		.fui-select.fui-error {
			color: #ff3b30;
			border-color: #ff3b30;
		}

		.fui-error-message {
			color: #ff3b30;
			@apply flex;
			@apply text-left;
		}
	}
	
	.message-textfield {
		textarea {
			height: 120px;

			@screen md {
				height: 180px;
			}
		}
	}

	input {
		.cursor {
			@apply text-primary-500;
		}
	}

	h3 {
		@apply text-h3-mobile md:text-h3 tracking-h3 font-kanit-medium mb-4;
	}

	h4 {
		@apply text-h4-mobile lg:text-h4 font-kanit-regular mb-2;
	}
}
