footer {
	.menu-item {
		@apply text-white;
		@apply font-chivo-regular;
		@apply text-body-s-mobile;
		@apply md:text-body-s;
		@apply tracking-body-s;
		@apply transition-all;

		&.active-tab {
			@apply underline;
		}

		&:hover {
			@apply text-secondary-500;

			hr {
				@apply border-secondary-500;
			}

			.arrow-right {
				@apply block;
			}
		}

		img {
			width: 12px;
			height: 12px;
		}
	}

	.social-media-icons {
		.social-media-icon {
			svg {
				path {
					fill: #fff;
				}
			}

			&:hover {
				svg {
					path {
						fill: #d7dc46;
					}
				}
			}
		}
	}

	.arrow-right {
		padding-top: 3px;

		@screen md {
			padding-top: 5px;
		}
	}

	.arrow-footer {
		img {
			width: 45px;
			height: 50px;
		}
	}
}
