#news-filter-sprig,
#search-sprig {
	&.htmx-request {
		.loading-cards {
			z-index: 10;
		}

		.news-container {
			z-index: 0;
			opacity: 0;
		}
	}

	.loading-cards {
		z-index: 0;
	}

	.news-container {
		transition: opacity 0.2s ease;
		opacity: 1;

		.news-post {
			box-shadow: 0 7px 14px rgba(64, 72, 70, 0.1);
			transition: box-shadow 0.2s ease;

			.news-footer {
				svg {
					path {
						fill: theme("colors.grey-700");
					}
				}
			}

			.text {
				transition: height 0.2s ease;

				h2 {
					@apply overflow-hidden;

					&.cut-text {
						/* stylelint-disable */
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						/* stylelint-enable */
					}
				}
			}

			.overlay {
				transition: opacity 0s ease 0s, height 0.2s ease 0.1s;
				@apply opacity-0;
				@apply h-0;

				@screen lg {
					&.cut-overlay-5 {
						/* stylelint-disable */
						display: -webkit-box;
						-webkit-line-clamp: 5;
						-webkit-box-orient: vertical;
						/* stylelint-enable */
					}

					&.cut-overlay-8 {
						/* stylelint-disable */
						display: -webkit-box;
						-webkit-line-clamp: 8;
						-webkit-box-orient: vertical;
						/* stylelint-enable */
					}

					&.hide {
						@apply hidden;
					}
				}
			}

			@screen lg {
				&:hover {
					@screen md {
						box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 3px 4px rgba(0, 0, 0, 0.12),
							0 1px 5px rgba(0, 0, 0, 0.2);

						.text {
							@apply h-82;

							h2 {
								@apply overflow-visible;
							}
						}

						.overlay {
							transition: opacity 0.2s ease 0.1s, height 0.2s ease 0.1s;
							@apply opacity-100;
							@apply h-auto;
							@apply overflow-hidden;
						}

						.news-footer svg path {
							fill: theme("colors.primary-600");
						}
					}
				}
			}
		}
	}

	.filter-button-container {
		button {
			transition: background-color 0.3s ease, color 0.3s ease, font-size 0.3s ease;

			&.active {
				@apply bg-complementary-300;
				@apply text-heading-dark;
			}

			&:hover {
				@apply bg-complementary-300;
				@apply text-heading-dark;
			}
		}
	}

	.load-more-container {
		.loading-container {
			z-index: -1;
			@apply opacity-0;
			transition: opacity 0.3s ease;
		}

		button {
			@apply bg-primary-500;
			@apply rounded-full;
			@apply text-white;
			@apply text-button;
			@apply font-kanit-medium;
			@apply py-4;
			@apply px-8;
			@apply z-10;
			@apply opacity-100;
			box-shadow: 0 3px 6px rgba(2, 73, 42, 0.08);
			transition: background-color 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;

			&:hover {
				@apply bg-primary-600;
				box-shadow: 0 7px 14px rgba(64, 72, 70, 0.1);
			}

			&.htmx-request {
				z-index: -1;
				@apply opacity-0;

				~ .loading-container {
					@apply z-10;
					@apply opacity-100;
				}
			}

			&.htmx-settling {
				z-index: -1;
				@apply opacity-0;

				~ .loading-container {
					@apply z-10;
					@apply opacity-100;
				}
			}
		}

		#indicator {
			&.htmx-request {
				z-index: -1;
				@apply opacity-0;

				~ .loading-container {
					@apply z-10;
					@apply opacity-100;
				}
			}
		}
	}

	.lds-ring {
		@apply inline-block;
		@apply relative;
		@apply w-16;
		@apply h-16;

		div {
			@apply box-border;
			@apply block;
			@apply absolute;
			@apply w-12;
			@apply h-12;
			@apply m-2;
			border: 6px solid theme("colors.primary-500");
			border-radius: 50%;
			animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
			border-color: theme("colors.primary-500") transparent transparent;
		}
	}

	.lds-ring div:nth-child(1) {
		animation-delay: -0.45s;
	}

	.lds-ring div:nth-child(2) {
		animation-delay: -0.3s;
	}

	.lds-ring div:nth-child(3) {
		animation-delay: -0.15s;
	}

	#loading-cards-load-more {
		&.htmx-request {
			@apply z-10;
			@apply opacity-100;
		}
	}
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.news-results {
	.news-article-headline {
		hyphens: auto;
	}
}
