.contest {
	.step {
		&.active {
			@apply flex;
		}
	}

	.answer-button {
		&.logged {
			@apply bg-secondary-400 border-transparent;
		}
		
		&.success {
			border: 1px solid theme("colors.primary-500") !important;
			
			.answer-check {
				display: inline-block;
			}
		}
		
		&.wrong {
			.answer-cross {
				display: inline-block;
			}
		}
	}

	.contest-form {
		.fui-type-heading {
			@apply mb-0;
	
			.fui-heading-h2 {
				@apply mb-2;
			}
			
			.fui-heading-h3 {
				margin-bottom: 1.5rem;

				@media (min-width: theme("screens.md")) {
					margin-bottom: 3.74rem;
				}
			}
		}

		.fui-alert-success {
			@apply hidden;
		}
	}

	.contest-form-success {
		.success-link {
			display: inline-block;
		}
	}
}
