.cookie-banner-tab {
	@apply flex;
	@apply fixed;
	@apply cursor-pointer;
	@apply rounded-t-xl;
	@apply left-2;
	@apply bg-primary-500;
	@apply font-chivo-regular;
	bottom: -30px;
	transition: bottom 0.3s ease;
	@apply z-10;

	&:hover {
		@apply bottom-0;
	}

	a {
		@apply px-5;
		@apply py-2;
		@apply text-heading-light;
	}
}

#cookie-banner {
	@apply font-chivo-regular;

	.cookie-notice {
		@apply w-full;
		@apply max-w-full;
		@apply left-0;
		@apply right-0;
		@apply bottom-0;

		.cn-body {
			@apply flex;
			@apply p-4;
			@apply w-full;
			@apply flex-col;
			@apply bg-heading-light;
			@apply text-heading-dark;

			@screen xl {
				@apply flex-row;
				@apply items-center;
				@apply justify-between;
			}

			a {
				@apply hover:underline;
			}
			
			p {
				@apply text-heading-dark;
				@apply w-full;
				@apply max-w-full;

				@screen xl {
					@apply w-3/5;
				}

				span {
					@apply max-w-full;
				}
			}

			.cn-ok {
				@apply mt-2;

				@screen xl {
					@apply mt-0;
				}
			}

			.cn-learn-more {
				@apply text-primary-500;
				@apply hover:underline;
			}
		}
	}

	.cm-modal {
		@apply bg-background;
		@apply text-heading-dark;

		p {
			@apply my-3;
		}

		a {
			@apply text-primary-500;
		}

		.hide svg {
			stroke: #073030;
		}

		p,
		label span {
			@apply text-heading-dark;
		}
		
		h1 {
			@apply text-heading-dark;
			@apply font-kanit-medium;
		}

		.cm-header,
		.cm-footer {
			@apply border-opacity-40;
		}

		.cm-body {
			@apply px-4;
			@apply py-6;
		}

		.cm-list-input:checked + .cm-list-label .slider {
			@apply bg-primary-500;

			&::before {
				@apply bg-white;
			}
		}

		.cm-list-input + .cm-list-label .slider {
			@apply bg-grey-200;

			&::before {
				@apply bg-opacity-20;
			}
		}

		.cm-purpose {
			@apply mb-6;

			&:last-child {
				@apply mb-0;
			}

			.purposes {
				@apply m-0;
			}
		}
	}

	.cn-buttons {
		@apply m-0;
		@apply flex;
		@apply w-full;
		@apply justify-between;

		@screen sm {
			@apply w-auto;
			@apply justify-end;
		}
	}

	.cm-footer-buttons {
		@apply justify-end;
	}

	.cm-btn-success {
		@apply bg-primary-500;
		@apply font-kanit-medium;
		@apply text-white;
		@apply uppercase;
		@apply rounded-full;
		@apply py-2;
		@apply px-4;
		@apply mr-0;
		@apply w-auto;
		@apply tracking-overline;

		&:hover,
		&:active {
			@apply bg-primary-600;
		}

		&:disabled {
			@apply bg-grey-500;
		}
			
	}

	.cn-decline {
		background: transparent;
		@apply hover:underline;
		@apply p-0;
		@apply text-left;
		@apply w-auto;
		@apply mr-4;
		@apply text-heading-dark;
	}

	.cm-btn-accept {
		@apply normal-case;
		@apply bg-primary-500;
		@apply font-chivo-bold;
		@apply text-white;
		@apply rounded-full;
		@apply py-2;
		@apply px-6;
		@apply mr-0;
		@apply w-auto;
		@apply tracking-overline;

		&:hover,
		&:active {
			@apply bg-primary-600;
		}

		&:disabled {
			@apply bg-grey-500;
		}
	}

	.cm-powered-by {
		@apply hidden;
	}
}
