.accordion-tabs {
	@screen md {
		@apply container;
	}
}

.accordion-tabs,
.accordion-tab-page {
	iframe {
		@apply rounded;
		@apply w-full;
	}
	
	video {
		@apply w-full;
		@apply h-auto;
		border-radius: 1%;
	}

	.rich-textfield-accordion-tab {
		p {
			@apply text-body-1;
			@apply text-body-m-mobile;
			@apply md:text-body-m;
			@apply font-chivo-regular;
			@apply pb-4;
			@apply md:pb-8;
			@apply tracking-body-m;

			a {
				@apply underline;
			}
		}

		h4 {
			@apply text-heading-dark;
			@apply text-h4-mobile;
			@apply md:text-h4;
			@apply font-kanit-medium;
			@apply pb-4;
			@apply pt-6;
		}

		ul {
			@extend .custom-ul;
		}

		ol {
			@extend .custom-ol;
		}
	}

	.description-accordion-tab {
		p {
			@apply text-heading-dark;
			@apply text-body-m-mobile;
			@apply md:text-body-m;
			@apply font-chivo-regular;
			@apply pb-4;
			@apply md:pb-8;
			@apply tracking-body-m;

			a {
				@apply underline;
			}
		}

		h4 {
			@apply text-heading-dark;
			@apply text-h4-mobile;
			@apply md:text-h4;
			@apply font-kanit-medium;
			@apply pb-4;
			@apply pt-6;
		}

		ul {
			@extend .custom-ul;
		}

		ol {
			@extend .custom-ol;
		}
	}

	.button-tab-icon-div {
		svg {
			width: 35px;
			height: 35px;

			@screen md {
				width: 48px;
				height: 48px;
			}
		}
	}

	.styling-div-icon {
		p {
			@apply text-grey-500;
		}

		svg {
			path {
				fill: #b4b8b8;
			}
		}
	}

	.click-div.active {
		@apply md:rounded-t-2xl;
		@apply bg-primary-500;
		@apply md:bg-white;

		@screen md {
			box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.1);
		}

		p {
			@apply text-primary-500;
		}

		svg {
			@apply text-white;
			@apply md:text-icon-accordion-tab;

			path {
				@apply fill-current;
			}
		}
	}
}
