
@font-face {
	font-family: "chivo-regular";
	font-display: swap;
	src: url("/src/fonts/chivo-regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "chivo-regular-italic";
	font-display: swap;
	src: url("/src/fonts/chivo-regular-italic.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "chivo-bold";
	font-display: swap;
	src: url("/src/fonts/Chivo-Bold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "kanit-regular";
	font-display: swap;
	src: url("/src/fonts/kanit-regular.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "kanit-medium";
	font-display: swap;
	src: url("/src/fonts/kanit-medium.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "kanit-semi-bold";
	font-display: swap;
	src: url("/src/fonts/kanit-semi-bold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: "kanit-bold";
	font-display: swap;
	src: url("/src/fonts/kanit-bold.woff2") format("woff2");
	font-weight: normal;
	font-style: normal;
}
