.button {
	a {
		&.flat {
			transition: all 0.3s ease;
			@apply p-0;
			@apply text-heading-dark;

			svg {
				transition: all 0.3s ease;

				path {
					fill: theme("colors.heading-dark");
				}
			}

			&:hover {
				@apply text-primary-600;

				svg {
					@apply transform;
					@apply translate-x-2;
					@apply transition;
					@apply duration-200;
					@apply ease-in-out;

					path {
						fill: theme("colors.primary-600");
					}
				}
			}
		}

		&.flat-light {
			transition: all 0.3s ease;
			@apply p-0;

			svg {
				transition: all 0.3s ease;
				
				path {
					fill: #fff;
				}
			}

			&:hover {
				@apply text-grey-200;

				svg {
					@apply transition;
					@apply duration-200;
					@apply ease-in-out;
					@apply transform;
					@apply translate-x-2;

					path {
						fill: theme("colors.grey-200");
					}
				}
			}
		}

		&.primary {
			transition: all 0.3s ease;

			&:hover {
				svg {
					@apply transition;
					@apply duration-200;
					@apply ease-in-out;
					@apply transform;
					@apply translate-x-2;
				}
			}
		}
		
		&.secondary {
			transition: color 0.3s ease;

			svg {
				transition: all 0.3s ease;
				
				path {
					fill: #fff;
				}
			}

			&:hover {
				svg {
					@apply transform;
					@apply translate-x-2;
					@apply transition;
					@apply duration-200;
					@apply ease-in-out;

					path {
						fill: theme("colors.primary-500");
					}
				}
			}
		}

		&.teritiary {
			transition: color 0.3s ease;
			transition: all 0.3s ease;

			svg {
				transition: all 0.3s ease;
				
				path {
					fill: #000;
				}
			}

			&:hover,
			&:active {
				border: 1px solid #fff;

				svg {
					@apply transform;
					@apply translate-x-2;

					path {
						fill: #000;
					}
				}

				a {
					@apply border-white;
				}
			}
		}
	}
}
