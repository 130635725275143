* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	min-height: 100%;
}

html {
	scroll-behavior: smooth;
	offset-anchor: auto;
	offset-anchor: right top;
	offset-anchor: left bottom;
	offset-anchor: 20% 80%;
}


// global swiper flickering fix
.swiper-slide {
	/* stylelint-disable */
	-webkit-transform: translate3d(0, 0, 0);
	/* stylelint-enable */
}

// Image Lazy Load Blurred Preview
img.ls-blur-up-img {
	display: none !important;
}

img.lazyloading.blur-lazy,
img.lazyload.blur-lazy {
	filter: blur(15px);
	transform: translateZ(0);
	transition: filter 0.35s ease-out;
	overflow: hidden;
}

img.lazyloaded.blur-lazy {
	transition: filter 0.35s ease-out;
	transform: translateZ(0);
	filter: blur(0);
}

img.lazyload:not([src]) {
	visibility: hidden;
}

// Set max width depending on design
.container {
	max-width: 1224px;
	@apply mx-auto;
	@apply px-4;
}

.row {
	@apply -mx-2;
	@apply md:-mx-3;
	@apply flex;
	@apply flex-wrap;

	&.hidden {
		display: none;
	}
}

.col {
	@apply px-2;
	@apply md:px-3;
}

// for media-queries use:
// 		@screen md {
// 			...
// 		}
.custom-ol {
	@apply font-chivo-regular;
	padding-left: 3rem;
	list-style: decimal;

	li {
		padding-bottom: 0.5rem;
		padding-left: 0.5rem;
		color: theme("colors.body-1");
	}
}

.custom-ul {
	@apply pl-5;

	li {
		@apply text-body-m-mobile;
		@apply md:text-body-m;
		@apply font-chivo-regular;
		@apply tracking-body-m;
		@apply pb-2;
		background-repeat: no-repeat;
		color: theme("colors.body-1");
		padding-left: 2.3rem;
		position: relative;

		&::before {
			position: absolute;
			content: "";
			background-image: url("../../images/ic_bulletpoint.svg");
			background-repeat: no-repeat;
			left: 0.5rem;
			top: 0.25rem;
			width: 1rem;
			height: 1rem;
			background-size: 100%;
		}
	}
}

span {
	&.highlighted-signal-green {
		@apply bg-secondary-500;
		@apply text-body-1;
	}

	&.strike-through,
	&.line-through {
		text-decoration: line-through;
	}
}

// redactor textfields default text color
.rich-textfield,
.answer-redactor-field,
.textfield-content-section,
.rich-textfield-accordion-tab {
	@apply text-body-1;
}
