.text-image {
	@apply relative;

	.rich-textfield {
		p {
			@apply text-body-1;
			@apply text-body-m-mobile;
			@apply md:text-body-m;
			@apply font-chivo-regular;
			@apply tracking-body-m;
			@apply pb-2;
		}
	}
}

.text-image-background-grey {
	.card-overflow-div {
		@screen lg {
			@apply container;
		}
	}

	.button-text-image-bg {
		@apply text-heading-dark;
		@apply font-chivo-bold;
		@apply text-button-mobile;
		@apply lg:text-button;
		@apply flex;

		&:hover {
			@apply underline;
		}

		&::after {
			content: "";
			background-image: url("../../images/ic_arrow_right_accordion.svg");
			background-repeat: no-repeat;
			height: 25px;
			width: 21px;
			@apply block;
			margin-left: 0.5rem;
			margin-top: -3px;
		}
	}

	.rich-textfield {
		p {
			@apply text-body-1;
			@apply text-body-m-mobile;
			@apply lg:text-body-l;
			@apply font-chivo-regular;
			@apply tracking-body-m;
			@apply pb-2;
		}

		a {
			@apply underline;

			&:hover {
				@apply text-primary-500;
			}
		}
	}

	.image-right {
		img {
			@apply rounded-tr-lg;

			@screen lg {
				@apply rounded-r-lg;
			}
		}
	}

	.image-left {
		img {
			@apply rounded-tl-lg;

			@screen lg {
				@apply rounded-l-lg;
			}
		}
	}

	.div-right {
		@apply rounded-br-lg;

		@screen lg {
			@apply rounded-r-lg;
		}
	}

	.div-left {
		@apply rounded-bl-lg;

		@screen lg {
			@apply rounded-l-lg;
		}
	}

	.border-right {
		border-right: 1px solid #000;
	}

	.border-left {
		border-left: 1px solid #000;
	}
}

.text-image-on-home {
	.h2-text-image-on-home {
		hyphens: auto;
	}
}
