.iframe-text-section,
.video-text-section {
	.iframe-text-div,
	.video-text-div {
		@screen lg {
			@apply container;
		}

		figure {
			@apply relative;
			@apply w-full;
			padding-bottom: 56.25%;
		}
	}

	iframe {
		@apply lg:rounded;
		@apply w-full;
		@apply absolute;
		top: 0;
		left: 0;
		@apply w-full;
		@apply h-full;
		border: 0;
	}

	video {
		@apply rounded;
	}
}
