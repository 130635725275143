.card-image-grid {
	.image-card {
		@apply transition-colors;

		&.active-card {
			@apply bg-primary-500;
			@apply text-white;

			.details-div h3,
			p {
				@apply bg-primary-500;
				@apply text-white;
			}
		}
	}
}
