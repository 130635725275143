.anniversary-section {
	.cards-line {
		&::after {
			content: "";
			@apply w-px;
			@apply h-full;
			@apply absolute;
			@apply top-0 left-1/2 -translate-x-2/4;
			@apply md:bg-grey-500 bg-grey-200;
		}
	}

	.more-text-container {
		.text-container {
			@apply max-h-0;
			@apply h-auto;
			@apply overflow-hidden;

			transition: max-height 0.3s;
		}

		.toggle-button {
			transition: color 0.3s;
	
			.show-more {
				@apply inline-block;
			}
		
			.show-less {
				@apply hidden;
			}

			.icon {
				transform: rotate(0deg);
				transition: transform 0.3s ease;
			}
	
			&:hover {
				@apply text-flat-btn-hover;

				svg {
					path {
						fill: theme("colors.flat-btn-hover");
					}
				}
			}
		}

		&.open {
			.toggle-button { 
				.show-more {
					@apply hidden;
				}

				.show-less {
					@apply inline-block;
				}

				.icon {
					transform: rotate(180deg);
					transition: transform 0.3s ease;
				}
			}
		}
	}
}
