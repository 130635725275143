.accordion,
.faq-tab-page {
	.add-icon {
		transition: all 0.5s ease-in-out;

		&.rotated {
			transform: rotate(45deg);
		}
	}

	.svg-div {
		svg {
			width: 23px;
			height: 23px;

			@screen md {
				width: 23px;
				height: 23px;
			}
		}
	}

	hr {
		@apply border-grey-500;
	}

	.active ~ hr {
		@apply border-primary-500;
	}

	.answer-faq {
		transition: all 0.25s ease-in-out;

		ul {
			@extend .custom-ul;
		}

		ol {
			@extend .custom-ol;
		}
	}

	.answer-redactor-field {
		p {
			@apply text-body-1;
			@apply text-body-m-mobile;
			@apply md:text-body-m;
			@apply font-chivo-regular;
			@apply pb-4;
			@apply md:pb-8;
			@apply tracking-body-m;

			a {
				@apply underline;
			}
		}

		h4 {
			@apply text-heading-dark;
			@apply text-h4-mobile;
			@apply md:text-h4;
			@apply font-kanit-medium;
			@apply pb-4;
		}

		.button-accordion {
			@apply text-heading-dark;
			@apply font-chivo-regular;
			@apply text-button-mobile;
			@apply md:text-button;
			@apply flex;

			&:hover {
				@apply underline;
			}

			&::after {
				content: "";
				background-image: url("../../images/ic_arrow_right_accordion.svg");
				background-repeat: no-repeat;
				height: 25px;
				width: 21px;
				@apply block;
				margin-left: 0.5rem;
				margin-top: -3px;
			}
		}
	}

	.plus-minus-toggle {
		cursor: pointer;
		height: 21px;
		position: relative;
		width: 21px;

		&::before,
		&::after {
			background: #009252;
			content: "";
			height: 2px;
			left: 0;
			position: absolute;
			top: 0;
			width: 21px;
			transition: transform 500ms ease;
		}

		&::after {
			transform-origin: center;
		}

		&::after {
			transform: rotate(90deg);
		}

		&::before {
			transform: rotate(180deg);
		}
	}

	.faq-block {
		&.active {
			hr {
				@apply border-primary-500;
			}

			.plus-minus-toggle {
				&::after {
					transform: rotate(0deg);
				}
	
				&::before {
					transform: rotate(0deg);
				}
			}
		}

	}
}
