.swiper-button-disabled {
	display: none;
}

.swiper-button {
	@apply cursor-pointer;

	svg {
		path {
			fill: #f3f2ed;
			@apply transition-colors;

			&:hover {
				fill: #e5e886;
			}
		}
	}
}

.gallery-lightbox {
	background-color: rgba(0, 0, 0, 0.85);

	.subtitle-div {
		.arrow-navigation {
			width: 48px;

			path {
				fill: #f3f2ed;
				@apply transition-colors;
			}

			&:hover {
				path {
					fill: #e5e886;
				}
			}
		}
	}

	.close-icon {
		path {
			fill: #f3f2ed;
			@apply transition-colors;
		}

		&:hover {
			path {
				fill: #e5e886;
			}
		}
	}

	.optimized-image-contain {
		img {
			object-fit: contain;
			object-position: 50% !important;
		}
	}
}
