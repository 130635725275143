.audio-embed {
	.rich-textfield {
		p {
			@apply text-body-1;
			@apply text-body-m-mobile;
			@apply md:text-body-m;
			@apply font-chivo-regular;
			@apply tracking-body-m;
			@apply pb-2;
		}
	}

	.button-audio-embed {
		@apply text-heading-dark;
		@apply font-chivo-regular;
		@apply text-button-mobile;
		@apply md:text-button;
		@apply flex;

		&:hover {
			@apply underline;
		}

		&::after {
			content: "";
			background-image: url("../../images/ic_arrow_right_accordion.svg");
			background-repeat: no-repeat;
			height: 25px;
			width: 21px;
			@apply block;
			margin-left: 0.5rem;
			margin-top: -3px;
		}
	}
}
