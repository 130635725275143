#search-component {
	input {
		@apply pb-2;
		@apply pl-10;
		@apply text-white;

		&:focus-visible {
			@apply border-none;
			outline: none;
			border-bottom-width: 2px;
			@apply border-secondary-600;
			@apply border-solid;
		}
	}

	::placeholder {
		/* Chrome, Firefox, Opera, Safari 10.1+ */
		color: #e1e0db;
		opacity: 1; /* Firefox */
	}
	/* stylelint-disable */
	:-ms-input-placeholder {
	/* stylelint-enable */
		/* Internet Explorer 10-11 */
		color: #e1e0db;
	}
	/* stylelint-disable */
	::-ms-input-placeholder {
	/* stylelint-enable */
		/* Microsoft Edge */
		color: #e1e0db;
	}

	.search-icon {
		height: 22px;
		width: 22px;

		path {
			fill: #fff;
		}
	}

	.close-icon {
		height: 22px;
		width: 22px;

		path {
			fill: #d7dc46;
		}
	}

	.title-preview-search {
		hyphens: auto;
	}
}
