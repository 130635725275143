.rich-text-login {
	p {
		@apply text-body-1;
		@apply text-body-m-mobile;
		@apply md:text-body-m;
		@apply font-chivo-regular;
		@apply pb-4;
		@apply md:pb-8;
		@apply tracking-body-m;

		a {
			@apply underline;
		}
	}

	ul {
		@extend .custom-ul;
	}

	ol {
		@extend .custom-ol;
	}
}
