.login-section {
	@screen md {
		height: 28rem;
	}

	@screen lg {
		height: 37rem;
	}

	@screen xl {
		height: 45rem;
	}

	@screen 2xl {
		height: 55rem;
	}


	.login-form {
		input {
			@apply w-full;
			@apply text-body-s;
			@apply py-2;
			@apply px-3;
			@apply rounded;
			@apply font-chivo-regular;
			@apply text-body-1;
			@apply border;
			@apply border-input;
			@apply bg-background;
		}

		input:focus {
			@apply font-chivo-regular;
			@apply text-body-s;
			@apply text-body-1;
			@apply outline-none;
			@apply border-input-focus;
			@apply bg-background;
			box-shadow: 0 0 0 3px rgb(164 202 254 / 45%);
		}

		.login-error {
			@apply text-error;
			@apply text-body-s;
			@apply font-chivo-regular;
			@apply text-body-1;
			@apply border-error;
		}

		.label {
			@apply font-chivo-regular;
			@apply text-body-s;
			@apply text-body-1;
			@apply text-textfield;
			@apply top-1;
			@apply left-4;
			@apply m-0;
			@apply p-1;
			@apply absolute;
			@apply bg-background;
			transition: top 0.2s, bottom 0.2s, right 0.2s, left 0.2s;

			&.float {
				@apply text-primary-500;
				@apply -top-4;
			}
		}
	}
}
