.icon-title-section {
	.rich-text {
		p {
			@apply text-body-1;
			@apply font-chivo-regular;
			@apply text-body-m-mobile;
			@apply lg:text-body-m;

			a {
				@apply underline;
			}
		}
	}

	.button-tab-icon-div {
		svg {
			width: 35px;
			height: 35px;

			@screen md {
				width: 48px;
				height: 48px;
			}
		}
	}

	img {
		width: 120px;
		height: 120px;
	}
	
	.lottie-player svg {
		width: 120px !important;
		height: 120px !important;
		@apply mx-auto;
	}
	
	.lottie-player-hover svg {
		width: 120px !important;
		height: 120px !important;
		@apply mx-auto;
	}
	
	.lottie-animations-div {
		@apply h-full;

		@screen md {
			height: 120px;
		}
	}

	.lottie-animations-div {
		.lottie-animation-div {
			@apply opacity-100;
		}

		.lottie-hover-animation-div {
			@apply opacity-0;
		}

		&:hover {
			.lottie-animation-div {
				@apply opacity-0;
			}
	
			.lottie-hover-animation-div {
				@apply opacity-100;
			}
		}
	}
}
