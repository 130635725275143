.cards-overlay {
	transition: height 0.2s ease;

	.card-block {
		transition: height 0.2s ease;
	}

	.card {
		transition: height 0.2s ease;

		.hover-div {
			transition: height 0.2s ease;
			@apply min-h-card-overlay;
			@apply lg:h-card-overlay;
		}

		.cards-overlay {
			transition: height 0.2s ease;
		}

		&:hover {
			@apply lg:shadow-2xl;
			transition: height 0.2s ease;

			.hover-div {
				height: 100%;
				overflow: hidden;
				height: 100%;
			}

			.cards-overlay {
				height: 500px;
			}

			.card-overlay {
				@apply block;
			}
		}

		.button-container-overlay {
			svg path {
				fill: #fff;

				&:hover {
					fill: #fefdf7;
				}
			}
		}
	}
}
