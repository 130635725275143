.header-menu {
	@apply z-20;
	@apply top-0;
	@apply bottom-0;
	@apply h-20;
	@apply overflow-hidden;
	@apply relative;
	// @apply sticky;

	.menu-item {
		.icon-white {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.icon-black {
			svg {
				path {
					fill: #000;
				}
			}
		}
	}

	.menu-item-language {
		&.text-light {
			@apply text-white;
		}

		&.text-dark {
			@apply text-black;
		}
	}

	.logo {
		width: 108.78px;
		height: 38.72px;
		
		@screen md {
			width: 141.43px;
			height: 50.34px;
		}

		svg {
			width: 108.78px;
			height: 38.72px;
		}

		@screen md {
			svg {
				width: 141.43px;
				height: 50.34px;
			}
		}
	}

	&.open-menu {
		overflow-y: scroll;
		-webkit-overflow-scrolling: touch;
		@apply h-full;
		@apply bg-primary-500;
		@apply fixed;

		.menu-item,
		.logo {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.menu-item-language {
			&.text-light {
				@apply text-white;
			}
	
			&.text-dark {
				@apply text-white;
			}
		}
	}

	#hamburger {
		margin-left: 3px;
		@apply md:ml-0;
		@apply mb-1.5;
		@apply md:mb-3.5;
	}

	.menu-item {
		padding: 0.5rem 0;
		@apply relative;

		svg {
			height: 22px;
			width: 24px;
		}

		@screen md {
			svg {
				@apply h-icon-header;
				@apply w-icon-header;
			}
		}

		a {
			@apply p-0;
			@apply flex;
			@apply flex-col;
			transition: color 0.2s ease;

			&.icon-black,
			&.icon-white {
				&:hover {
					svg {
						path {
							fill: #d7dc46;
						}
					}
				}
			}

			@screen md {
				&:hover {
					@apply bg-transparent;

					&::after {
						@apply w-full;
					}
				}
			}
		}

		&.active-tab {
			@screen md {
				a {
					&::after {
						@apply w-full;
					}
				}
			}
		}
	}

	.menu-icon {
		@apply cursor-pointer;
		@apply select-none;
		@apply px-2.5;
		@apply py-5;
		@apply bg-transparent;
		@apply border-0;
		font-size: 0;

		&:focus {
			@apply outline-none;
		}

		.navicon {
			@apply block;
			@apply relative;
			transition: all 0.2s ease;
			@apply rounded-full;
			@apply h-0.5;
			@apply w-5;

			@screen md {
				height: 0.188rem;
				width: 34px;
			}

			&.hamburger-icon-white {
				@apply bg-white;

				&::before,
				&::after {
					@apply bg-white;
				}
			}

			&.hamburger-icon-black {
				@apply bg-black;

				&::before,
				&::after {
					@apply bg-black;
				}
			}

			&::before,
			&::after {
				content: "";
				@apply block;
				@apply h-full;
				@apply absolute;
				transition: all 0.2s ease;
				@apply w-full;
				@apply rounded-full;
			}

			&::before {
				@apply top-0.5;
				@apply md:top-3;
			}

			&::after {
				@apply -top-2;
				@apply md:-top-3;
			}
		}

		&.close {
			.navicon {
				@apply bg-transparent;

				&::before {
					@apply transform;
					@apply -rotate-45;
					@apply bg-white;
				}

				&::after {
					@apply transform;
					@apply rotate-45;
					@apply bg-white;
				}
			}

			&:not(.steps) .navicon::before,
			&:not(.steps) .navicon::after {
				@apply top-0;
			}
		}
	}

	#menu {
		@apply bg-transparent;

		&.open {
			@apply block;
			@apply flex-col;
			@apply w-full;
			@apply h-screen;
			@apply z-20;
			@apply mb-4;
			@apply md:mb-0;

			.menu-items {
				@apply h-auto;
			}
		}

		hr {
			@apply border-primary-600;

			&:hover,
			&:active {
				@apply border-secondary-500;
			}
		}

		.menu-link {
			&:hover {
				@apply italic;
			}
		}

		.menu-item-link {
			&:hover,
			&:active {
				li {
					hr {
						@apply border-secondary-500;
					}

					.menu-link {
						@apply italic;
					}
				}
			}

			&:active {
				hr {
					@apply border-secondary-500;
				}
			}
		}

		.menu-item-div {
			.list-item-icon {
				path {
					fill: #fff;
				}
			}

			&:hover,
			&.active-tab {
				hr {
					@apply border-secondary-500;
				}

				.menu-link {
					@apply italic;
				}
			}

			&:active {
				hr {
					@apply border-secondary-500;
				}
			}

			.subbuttons {
				transition: max-height 0.2s ease-in-out;
			}

			.subbutton {
				&:hover {
					@apply italic;
				}
			}

			.subbutton {
				&:hover {
					@apply italic;
				}

				&.active {
					@apply italic;

					hr {
						@apply border-secondary-500;
					}
				}
			}
		}
	}

	.open-menu-only {
		@apply hidden;
	}

	.closed-menu-only {
		@apply hidden;
	}

	.plus-minus-toggle {
		cursor: pointer;
		height: 21px;
		position: relative;
		width: 21px;

		&::before,
		&::after {
			background: #fff;
			content: "";
			height: 2px;
			left: 0;
			position: absolute;
			top: 0;
			width: 20px;
			transition: transform 500ms ease;

			@screen md {
				width: 31px;
			}
		}

		&::after {
			transform-origin: center;
		}

		&.collapsed {
			&::after {
				transform: rotate(-90deg);
			}

			&::before {
				transform: rotate(-180deg);
			}
		}
	}
}
